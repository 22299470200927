import React, { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_KEY } from '@Constants/index';

ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
ReactGA.pageview(window.location.pathname + window.location.search);

const Analytics: React.FC = (): JSX.Element => {
  const history = useHistory();
  useEffect(() => {
    return history.listen((location) => { 
      ReactGA.pageview(window.location.pathname + window.location.search);
    }) 
  },[history]);

  return (
    <div></div>
  );
};

export default withRouter(Analytics);
