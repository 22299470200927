import React from 'react';
import { Container } from 'react-bootstrap';

import './Loading.scss';

const Loading: React.FC = (): JSX.Element => {
  return (
    <div className="container">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
      <p className="loading-des">Loading...</p>
    </div>
  );
};

export default Loading;
