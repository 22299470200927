import React, { Children, useState } from 'react';

import { Place } from '@Constants/models/place.types';
import { Retention } from '@Constants/models/retention.types';
import { Account } from '@Constants/models/account.types';

const defaultState: any = {
  loading: false,
  place: {},
  retention: {},
  account: {},
  location: {},
  user: {},
  setLoading: (status: boolean) => {},
  setPlace: (place: Place) => {},
  setRetention: (retention: Retention) => {},
  setAccount: (account: Account) => {},
  setLocation: (location: Location) => {},
  setUser: (user: Account) => {}
};

const AppContext = React.createContext(defaultState);

function AppProvider(props: any) {
  const [loading, setGlobalLoading] = useState<boolean>(defaultState.loading);
  const setLoading = (status: boolean) => setGlobalLoading(status);

  const [place, setGlobalPlace] = useState<Place>(defaultState.place);
  const setPlace = (place: Place) => setGlobalPlace(place);

  const [location, setGlobalLocation] = useState<Location>(defaultState.location);
  const setLocation = (location: Location) => setGlobalLocation(location);

  const [retention, setGlobalRetention] = useState<Retention>(
    defaultState.retention
  );
  const setRetention = (retention: Retention) => setGlobalRetention(retention);

  const [account, setGlobalAccount] = useState<Account>(defaultState.account);
  const setAccount = (account: Account) => setGlobalAccount(account);

  const [user, setGlobalUser] = useState<Account>(defaultState.user);
  const setUser = (user: Account) => setGlobalUser(user);

  return (
    <AppContext.Provider
      value={{
        loading,
        place,
        retention,
        account,
        location,
        user,
        setLoading,
        setPlace,
        setRetention,
        setAccount,
        setLocation,
        setUser
      }}
    >
      {Children.toArray(props.children)}
    </AppContext.Provider>
  );
}

export { AppContext, AppProvider };
