import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from '@Components/PrivateRoute';
import Layout from '@Components/Layout/Loadable';
import Home from '@Containers/Home/Loadable';
import RecordFinish from '@Containers/RecordFinish/Loadable';
import Offer from '@Containers/Offer/Loadable';
import Thanks from '@Containers/Thanks/Loadable';
import RecordFail from '@Containers/RecordFail/Loadable';
import NotFound from '@Containers/NotFound/Loadable';
import LandingPage from '@Containers/LandingPage/Loadable';
import './assets/scss/styles.scss';
import Analytics from './Analytics';
import 'react-toastify/dist/ReactToastify.css';
import '@material/react-material-icon/dist/material-icon.css';
import Loading from '@Containers/Loading';
const App: React.FC = (): JSX.Element => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <ToastContainer />
        <Layout>
          <Switch>
            <Route path="/404/error" exact component={NotFound} />
            <Route exact path="/record-finish" component={RecordFinish} />
            <Route exact path="/job/:name/:id" component={LandingPage} />
            <Route exact path="/thanks" component={Thanks} />
            <Route exact path="/record-fail" component={RecordFail} />
            <Route exact path="/:uuid?" component={Home} />
            <Route exact path="/offer/:uuid" component={Offer} />
            <Route path="*" exact component={NotFound} />
          </Switch>
          <Analytics />
        </Layout>
      </Suspense>
    </Router>
  );
};

export default App;
